<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
     <v-flex xs12>
      <v-layout wrap>
          <v-flex xs4 text-left>
              <span class="mainHeader">MENU MANAGEMENT</span>
            </v-flex>
      </v-layout>
     </v-flex>
      <v-flex xs12 py-6 text-left v-if="rolesList">
        <v-card
          tile
          elevation="0"
          class="rounded-lg"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap justify-start v-if="rolesList.length>0">
          <v-flex
            xs12
            md6
            sm6
            lg4
            xl3
            align-self-center
            v-for="(item, i) in rolesList"
            :key="i"
            fill-height
            pa-4
          >
            <v-card tile color="#e5e1ff">
              <v-layout wrap justify-end py-4>
                 <v-flex xs12 align-self-start text-left px-3 >
                  <span class="showLine2 tablefont">Role  :{{item.roles}}</span> 
                </v-flex>
                <v-flex xs6 sm4 md8 align-self-center text-left px-2>
                  <v-dialog persistent v-model="item.menu" max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        tile color="#3F053C"
                        outlined
                        small
                        plain
                        @click="getPosition(item._id)"
                        class="text-capitalize"
                      >
                        <span class="tablefont1">Menu management</span>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-layout wrap>
                        <v-flex
                          xs12
                          align-self-center
                          pa-5
                          style="background: #3F053C"
                          text-left
                        >
                          <span class="tablefont1" 
                            >Menu Management - {{ item.roles }}</span
                          >
                        </v-flex>
                        <v-flex xs12 pa-5 align-self-center v-if="menuData">
                          <v-layout wrap v-if="menuData">
                            <v-flex xs12 pa-2 align-self-center text-center>
                              <span style="font-size:20px;border-bottom:1px solid #000;" class="mainHeader2">
                                Select/deselect menus
                              </span>
                            </v-flex>
                            <v-flex
                              xs12
                              align-self-center
                              pa-2
                              v-if="menuData.length > 0"
                            >
                              <v-container
                                v-for="(item, j) in menuData"
                                :key="j"
                              >
                                <v-layout wrap justify-center>
                                  <v-flex xs12 sm10 align-self-center>
                                    <v-checkbox
                                      v-model="item.isChecked"
                                      :label="item.name"
                                      color="#3F053C"
                                      hide-details
                                      @click="reCheck(item, item.isChecked)"
                                    ></v-checkbox>
                                  </v-flex>
                                </v-layout>
                                <v-container v-if="item.isChecked">
                                  <v-layout
                                    wrap
                                    justify-center
                                    v-for="(sub, k) in item.submenus"
                                    :key="k"
                                  >
                                    <v-flex xs12 sm10 px-4 align-self-center>
                                      <v-checkbox
                                        v-model="sub.isChecked"
                                        :label="sub.name" 
                                        color="#3F053C"
                                        hide-details
                                      ></v-checkbox>
                                    </v-flex>
                                  </v-layout>
                                </v-container>
                              </v-container>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 align-self-center text-center v-else pa-5>
                          <span class="tablefont1">No menus found!</span>
                        </v-flex>
                      </v-layout>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="#3F053C"
                          tile
                          outlined
                          @click="item.menu = false"
                          >Cancel</v-btn
                        >
                        &nbsp;
                        <v-btn
                          color="#3F053C"
                          dense
                          class="buttons1"
                          
                          @click="updateMenu(i)"
                          >Save menu</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
                <v-spacer></v-spacer>
                <!-- <v-flex xs6 sm4 md2 pa-2>
                  <v-dialog persistent v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="font-family: kumbhBold"
                        class="mr-2"
                        fab
                        outlined
                        depressed
                        dark
                        title="Delete"
                        v-on="on"
                        v-bind="attrs"
                        color="#3F053C"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-layout wrap>
                        <v-flex
                          xs12
                          align-self-center
                          pa-4
                          style="background: #3F053C"
                          text-left
                        >
                          <span class="kumbhBold" style="color: #ffffff"
                            >Delete Confirmation</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-card-title
                        >Are you sure you want to delete this
                        Position?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error"
                          tile
                          dark
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        &nbsp;
                        <v-btn
                          color="#3F053C"
                          outlined
                          tile
                          @click="deleteItem(item)"
                          >Delete</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex> -->
                <!-- <v-flex xs6 sm4 md2 pa-2>
                  <v-btn
                    color="#3F053C"
                    style="font-family: kumbhBold"
                    small
                    dark
                    outlined
                    title="Edit"
                    depressed
                    fab
                    @click="editcat(item)"
                  >
                    <v-icon>mdi-pen</v-icon>
                  </v-btn>
                </v-flex> -->
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout v-else wrap justify-start>
          <v-flex xs12 pa-4 text-center class="tablefont1"> No roles found please add roles in staff</v-flex>
        </v-layout>
          </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      menuData:[],
      rolesList:[],
    };
  },
  mounted() {
    this.getRoless();
    this.getMenu();
  },
  methods: {
     getMenu() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/admin/menu/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.menuData = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getRoless() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/getalllist/roles",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.rolesList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
     reCheck(item, check) {
      if (!check) {
        for (let i = 0; i < item.submenus.length; i++) {
          item.submenus[i].isChecked = false;
        }
      }
    },
    updateMenu(i) {
      this.appLoading = true;
      var data = {};
      data["roleId"] = this.positionId;
      data["menus"] = this.menuData;
      axios({
        url: "/rolemenu/add/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true; 
            this.menuData = null ; 
            this.rolesList[i].menu=false; 
            this.getMenu();

          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
     getPosition(id) {
      this.positionId=id
      this.menuData=null
      this.appLoading = true;
      axios({
        url: "/get/roles",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.menuData = response.data.menus;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
